@charset "utf-8";
/* CSS Document */

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}


/* ++++++++++++++++++++ */
/* +++ sns_01 +++++++++ */
/* ++++++++++++++++++++ */

.sns_01 h2{
	font-family:Arial, Helvetica, sans-serif;
	font-size:34px;
	text-align:center;
	font-weight:normal;
}

.sns_01 hr.line_1{
	border:none;
	background:#333;
	width:1px;
	height:50px;
	display:block;
	margin:20px auto 40px auto;
}
.sns_01{ padding:50px 0 100px 0;}
.sns_01 ul{ width:32%; margin:0 auto;}
.sns_01 li{ width:33%; text-align:center; padding:0 0 20px 0;}
.sns_01 li:nth-child(3){ }

.sns_01 li img{ width:65%; display:block; margin:0 auto; }


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.sns_01 ul{ width:70%; margin:0 auto;}
.sns_01 li img{ width:60%; display:block; margin:0 auto; }
}
@media screen and (max-width: 330px) {
}



/* +++++++++++++++++++++++++++++++++++++++ */
/* + footer ++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */

#footer_00{
	background:#ececec;
	padding:20px 0;
}


#footer_00 ul{	width:100%;}
#footer_00 li:nth-child(1){	width:15%;	padding:0;}
#footer_00 li:nth-child(2){	width:15%;	padding:0;}
#footer_00 li:nth-child(3){	width:17%;	padding:0;}
#footer_00 li:nth-child(4){	width:19%;	padding:0;}
#footer_00 li:nth-child(5){	width:15%;	padding:0;}
#footer_00 li:nth-child(6){	width:15%;	padding:0;}



#footer_00 li a{
	display:block;
	color:#333;
	text-decoration:none;
	text-align:center;
	padding:20px 0;
}
	

#footer_00 p.copyright_01{ text-align:center; font-size:12px; padding:50px 0 0 0;}



@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
#footer_00 li{	width:50%;	padding:0; font-size:14px;}
#footer_00 li a{padding:10px 0;}
}
@media screen and (max-width: 330px) {
#footer_00 li{	width:100%;	padding:0;}
#footer_00 li a{padding:10px 0;}
}


